@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-Medium.317a26a1.woff2) format("woff2"),
    url(/static/media/Gilroy-Medium.c96412c6.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-Bold.2141f4a9.woff2) format("woff2"),
    url(/static/media/Gilroy-Bold.05c4dc42.woff) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-Regular.af58a390.woff2) format("woff2"),
    url(/static/media/Gilroy-Regular.7c1655b6.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-Black.6292fec2.woff2) format("woff2"),
    url(/static/media/Gilroy-Black.f563272b.woff) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-Light.603aa5c4.woff2) format("woff2"),
    url(/static/media/Gilroy-Light.9e55e02c.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  background: #02040b;
  color: white;
  font-family: "Gilroy", Helvetica, sans-serif !important;
  font-size: 18px;
  overflow-y: scroll;
}

.mw-1920 {
  width: 90%;
  max-width: 1920px;
  margin: 0 auto;
}

.mw-1240 {
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;
}

.btn {
  font-size: 1em;
}

.btn:hover {
  color: inherit;
}

.dark-textfield {
  background: #1b1b1b;
  border-radius: 7px;
  display: block;
}

.dark-textfield input,
.dark-textfield textarea {
  color: gray;
  padding: 1em;
}

.account-info h5 {
  padding: 1em 0 0.75em;
  font-size: 1em;
}

.sidebar li {
  list-style: none;
  padding: 0.75em 0;
}

.btn.subtle {
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d0d5d9;
}

.btn.blue {
  background: #282bf6;
  color: white;
}

.btn.gradient {
  color: white;
  background: linear-gradient(45deg, #ef4135, #ff7532);
}

.border-radius {
  border-radius: 10px;
}

.post-placeholder {
  position: absolute;
  top: 0;
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  font-weight: bold;
  font-size: 1.2vw;
  line-height: 1;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.placeholder-ani {
  -webkit-animation: placeholderAnimate 5s alternate infinite;
          animation: placeholderAnimate 5s alternate infinite;
}

@-webkit-keyframes placeholderAnimate {
  0% {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }

  100% {
    -webkit-filter: hue-rotate(-60deg);
            filter: hue-rotate(-60deg);
  }
}

@keyframes placeholderAnimate {
  0% {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }

  100% {
    -webkit-filter: hue-rotate(-60deg);
            filter: hue-rotate(-60deg);
  }
}

a {
  text-decoration: none;
}

header {
  margin-bottom: 1em;
}

header .container {
  padding: 1em 0;
  width: 95%;
  max-width: 1920px;
  margin: 0 auto;
}

header a {
  color: inherit;
}

header .nav-item {
  margin-right: 1.25em;
  cursor: pointer;
}

.post-modal-button {
  margin-top: 20px;
}

.post-modal-content {
  padding: 0.5em;
}

.post-modal-content .comments {
  padding: 2em 0.5em;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 10px;
}

.MuiDialog-paper {
  max-width: 100vw !important;
}

.banner {
  border-radius: 10px;
  padding: 2em 0 3em;
  margin-bottom: 1em !important;
}

.banner img {
  max-width: 100%;
}

.banner p {
  margin-bottom: 1.2em;
  color: #d0d5d9;
}

.post {
  background: #1b1b1b;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.54) 0px 20px 30px;
  transition: 0.2s;
  cursor: pointer;
}

.post:hover {
  transition: 0.2s;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

.post-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
}

.post-caption {
  padding: 0 2em 2em 2em;
}

.post-votes-count {
  margin-right: 1em;
}

.post-votes-count i {
  color: #ff3a3a;
}

.post-comments-count i {
  color: #3399ff;
}

.post-modal-caption {
  font-size: 1em;
  width: 100%;
}

.post-username {
  font-weight: bold;
}

.post-user {
  align-items: center;
  display: flex;
}

.comment-reply {
  margin-top: 1em;
}

.comment-reply,
.comment-text {
  margin-left: 3em;
}

.comment-reply span,
.comment-text span {
  cursor: pointer;
}

.comment-reply i.fa-reply,
.comment-text i.fa-reply {
  color: #3399ff;
}

.comment-reply i.fa-trash,
.comment-text i.fa-trash {
  color: #ff3a3a;
  margin-left: 0.75em;
  margin-right: 5px;
}

.comment-reply p,
.comment-text p {
  margin-bottom: 0;
}

.post-user-avatar {
  margin-right: 0.5em;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.post-user-avatar.large-avatar {
  height: 128px;
  width: 128px;
}

.reply-avatar {
  height: 32px;
  width: 32px;
}

.post-meta {
  display: flex;
  font-size: 0.8em;
}

.mw-1920 {
  max-width: 1920px;
  margin: 0 auto;
}

.bg-image {
  background-size: cover;
  background-position: center;
}

.post-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1em;
  padding-bottom: 4em;
}

.post-list .post-image {
  border-radius: 5px;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
  position: relative;
}

.post-list .post-image i {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 2em;
  text-shadow: rgba(0, 0, 0, 0.54) 0px 20px 30px;
  z-index: 1;
  position: absolute;
  top: 0;
}

.dropdown-show:hover>.dropdown-desktop,
.dropdown-desktop:hover {
  transition: 100ms ease-in-out;
  opacity: 1;
  display: block;
  visibility: visible;
}

.dropdown-desktop {
  opacity: 0;
  padding: 1em;
  position: absolute;
  z-index: 10;
  top: 40px;
  width: 400px;
  visibility: hidden;
  transition: 100ms ease-in-out;
  left: 0px;
  background: #444444;
  padding: 2em;
  border-radius: 10px;
}

.signup-wrapper {
  min-height: 540px;
  align-items: center;
  display: flex;
}

.w-container {
  background: white;
  color: black;
  max-width: 500px;
  margin: 0 auto;
  padding: 2em;
}

.highlight {
  border-radius: 5px;
  padding: 0.5em;
}

.highlight.error {
  background: #f79c9c;
  color: #802b2b;
}

.commenter {
  margin: 3em 0;
  max-width: 600px;
}

.avatar {
  display: inline-block;
}

.avatar-select {
  padding: 4em;
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
}

.avatar-select h4,
.avatar-select h5 {
  padding: 0.5em;
}

.avatar-select h5 {
  margin-top: 1em;
}

.icon-box,
.color-box {
  cursor: pointer;
}

.color-box {
  width: 30px;
  height: 42.7px;
}

.avatar-select {
  padding: 0 4em 4em 4em;
}

.icon-select,
.color-select {
  max-width: 400px;
  flex-wrap: wrap;
}

.icon-box {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.icon-box img {
  max-width: 100%;
}

.color-select {
  display: flex;
}

.icon-select {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.icon-box.active,
.color-box.active {
  border: 2px solid #484848;
}

#aff-dashboard .metric {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
}

#aff-dashboard .data-box {
  background-color: #1b1b1b;
  padding: 2em;
  border-radius: 25px;
  box-shadow: 6px 5px 20px rgba(0, 0, 0, 0.8);
}

#aff-dashboard .data-box .fa-info-circle {
  color: #3399ff;
}

#aff-dashboard .data-box .icon-bubble {
  padding: 1em;
  border-radius: 10px;
  display: table;
  margin-bottom: 2em;
}

#aff-dashboard .data-box h2 {
  font-weight: bold;
  font-size: 4em;
}

#aff-dashboard .data-box h3 {
  font-weight: bold;
  color: gray;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1em;
}

.bubble {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-bottom: 2em;
}

.bubble.green {
  background: #2b902b;
}

.dl-box {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5em;
  margin-bottom: 1em;
  border-radius: 50px;
  justify-content: space-between;
  color: white;
  transition: 0.2s -webkit-transform ease-in-out;
  transition: 0.2s transform ease-in-out;
  transition: 0.2s transform ease-in-out, 0.2s -webkit-transform ease-in-out;
}

.dl-box:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  transition: 0.2s -webkit-transform ease-in-out;
  transition: 0.2s transform ease-in-out;
  transition: 0.2s transform ease-in-out, 0.2s -webkit-transform ease-in-out;
}

.dl-box .icon-bubble {
  background: white;
  width: 40px;
  height: 40px;
  text-align: left;
  color: black;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}

.payment-box {
  display: block;
  max-width: 460px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3em;
  text-align: center;
  background-color: #d0d5d9;
  border-radius: 10px;
  margin-bottom: 2em;
}

a.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  color: inherit;
}

.reply-commenter {
  margin-left: 4em;
}

.post-meta span {
  font-size: 0.75em;
  margin: 0 0.6em;
}

.search-header i {
  color: white;
}

.dialog-enter {
  opacity: 0.01;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.dialog-enter-active {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: all 300ms;
}

.dialog-exit-active {
  opacity: 0;
  transition: all 300ms;
}

.post-modal {
  max-width: 800px;
  margin: 0 auto;
}

a {
  color: #ff3a3a;
}

.autocomplete-dropdown-container {
  padding: 0.8em;
  text-align: left;
}

.suggestion-item,
.suggestion-item--active {
  padding: 0.75em 0.5em;
  font-size: 0.8em;
}

.dialog-container {
  max-width: 960px;
}

.comment {
  margin: 1em 0;
  background: #e7e7e7;
  padding: 1em;
  border-radius: 8px;
}

.profile-stats .fa-heart {
  color: #ff3a3a;
}

.profile-stats .fa-comment-lines {
  color: #3399ff;
}

.profile-stats .fa-map-marker-alt {
  color: #697790;
}

.profile-stats span {
  margin-right: 1em;
}

.profile-nav {
  margin: 2em 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

.profile-nav .nav-item {
  padding: 0 1em 1em 1em;
  cursor: pointer;
}

.profile-nav .nav-item:hover {
  color: #3399ff;
}

.profile-nav .nav-item.active {
  border-bottom: 2px solid #3399ff;
}

.top-post {
  display: none;
}

.featured-post-images {
  position: relative;
  display: none;
}

.mobile-hide {
  display: none !important;
}

.mobile-menu {
  padding: 1em;
  height: 100vh;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
  background: black;
}

.mobile-menu .nav-link {
  font-size: 1.25em;
  color: inherit;
  padding-bottom: 1em;
}

.MuiStepLabel-label {
  color: white !important;
  font-size: 1.2em !important;
  font-weight: normal !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #327c49 !important;
}

svg.MuiSvgIcon-root.MuiStepIcon-root {
  color: #494e60;
}

svg.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active {
  color: #f45134;
}

p.fine {
  font-size: 0.75em;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  margin: 1em 0;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.react-player video {
  object-fit: cover;
}

.profile-editor {
  background-color: #03070b;
}

.profile-editor .profile-nav {
  min-height: 75px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000000;
  width: 100%;
  background: #09151e;
  display: flex;
  justify-content: space-between;
}

.profile-editor .profile-nav .flex-container {
  display: flex;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
}

.profile-editor .profile-nav .flex-container .profile-info {
  display: flex;
}

.profile-editor .profile-nav .nav-item {
  padding: 1em;
  border-right: 1px solid #06080a;
}

.profile-editor .profile-nav .nav-item h5 {
  color: #73787e;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
  padding-top: 10px;
}

.profile-editor .profile-nav .nav-item p {
  color: white;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.profile-editor .profile-nav svg {
  fill: #3399ff;
}

.profile-editor .profile-nav label {
  color: #73787e;
}

.profile-editor .profile-nav input {
  color: white;
}

.profile-editor .collapsed {
  width: 0% !important;
}

.profile-editor .profile-col::-webkit-scrollbar {
  display: none;
}

.profile-editor .fixed-col-head {
  height: 3em;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
}

.profile-editor .active-col {
  height: 90vh;
}

.profile-editor .profile-col {
  height: 100vh;
  position: relative;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  border-left: 1px solid #08131b;
  border-top: 1px solid #08131b;
}

.profile-editor .profile-col h3 {
  color: #73787e;
  font-weight: normal;
  font-size: 1em;
  padding-left: 1.5em;
}

.profile-editor .pc-1 {
  background: #010407;
}

.profile-editor .pc-1 .MuiFilledInput-underline:after {
  border-bottom: none;
}

.profile-editor .pc-1 label {
  color: #73787e !important;
}

.profile-editor .pc-2 {
  background: #03070b;
}

.profile-editor .pc-3 {
  background: #03070b;
}

.profile-editor .profile-option {
  cursor: pointer;
  font-size: 16px;
  border-bottom: 1px solid #08131b;
  width: 100%;
  padding: 0.5em 0;
}

.profile-editor .profile-option .profile-button {
  color: white;
  min-width: 300px;
  display: table;
  margin-left: 1em;
  border: 1px solid rgba(255, 255, 255, 0.322);
  padding: 10px 20px;
  border-radius: 50px;
}

.profile-editor .profile-option .profile-button i {
  color: #3399ff;
}

.profile-editor .profile-option .profile-checkboxes {
  padding-left: 1em;
  color: white;
}

.profile-editor .profile-option .profile-checkboxes svg {
  fill: #3399ff;
}

.profile-editor .profile-option .arrow {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
}

.profile-editor .profile-option .active {
  transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.profile-adder {
  background: #f2f2f2;
  padding: 2em;
  width: 650px;
}

.profile-adder h3 {
  color: black;
  font-size: 1.1em;
  font-weight: bold;
}

.btn-group-dynamic {
  display: flex;
  align-items: baseline;
}

.fixture-search .search-results {
  max-height: 200px;
  overflow-y: scroll;
}

.fixture-search .search-results .line-item {
  color: black;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  padding: 20px;
  font-size: 16px;
}

.fixture-search .search-results .line-item .fa-trash-alt {
  color: #ff3a3a;
}

.fixture-search .search-results .line-item .fa-edit {
  color: #697790;
}

.fixture-search .search-results .line-item i {
  font-size: 18px;
  padding-left: 2em;
}

.profile-channel.active {
  background: #09151e;
}

.profile-channel {
  padding-left: 2em;
  align-items: center;
  border-bottom: 1px solid #08131b;
  color: white;
}

.profile-channel .profile-order {
  text-align: center;
  color: #73787e;
  font-size: 1em;
  padding-right: 1em;
}

.profile-channel .channel-name {
  display: flex;
  align-items: center;
  font-size: 1.5em;
}

.trip-text {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 3em;
  color: black;
  letter-spacing: 14px;
  text-shadow: -5px -4px 0px #009cff99, 2px 4px #ff0045c7;
}

#channel-name-edit,
#new-profile-name {
  color: white !important;
  border: none;
}

#segment-name-edit {
  background: none;
  border: none;
  font-size: 16px;
  text-align: center;
}

#segment-name-edit:focus-visible {
  outline: none !important;
}

#available-channels {
  color: white !important;
}

.bare-text-input {
  border: none;
  background: transparent;
  outline: none !important;
}

.debounce-input {
  padding: 1.2em;
  background: transparent;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
}

@media only screen and (min-width: 768px) {
  .post-list {
    grid-template-columns: repeat(2, 1fr);
  }

  #aff-dashboard .metric {
    grid-template-columns: repeat(2, 1fr);
  }

  .post-modal-content {
    padding: 2em;
  }

  .post-modal-caption {
    font-size: 1.2em;
  }
}

@media only screen and (min-width: 1024px) {
  h1 {
    font-size: 3em;
  }

  .banner p {
    font-size: 1.2em;
  }

  .post-list {
    grid-template-columns: repeat(3, 1fr);
  }

  #aff-dashboard .metric {
    grid-template-columns: repeat(3, 1fr);
  }

  .post-modal-content {
    min-width: 600px;
    padding: 2em;
  }

  .top-post {
    display: inherit;
    position: absolute;
    bottom: -40px;
    left: -40px;
    width: 50% !important;
    box-shadow: 1px 1px 20px 6px #0a0a0a;
  }

  .featured-post-images {
    display: inherit;
  }

  .mobile-hide {
    display: inherit !important;
  }

  /* .desktop-hide {
    display: none !important;
  } */
}

@media only screen and (min-width: 1240px) {
  .post-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

.warranty-center {
  background: rgb(8, 16, 22);
  min-height: 100vh;
  padding-bottom: 5em;
}

.warranty-center input#outlined-basic {
  width: 100%;
  border: none;
  border-radius: 0px;
  color: black;
  padding: 10px 10px 14px 10px;
}

.warranty-center h4 {
  margin: 0;
  color: #3e4a52;
  font-size: 1em;
  padding-top: 1em;
  font-weight: 600;
}

.warranty-center .info-form {
  background: #440dd7;
  padding: 2em;
  border-radius: 8px;
}

.warranty-center .info-form h3 {
  color: rgb(255 255 255);
  padding-bottom: 0.75em;
}

.warranty-center .button-group {
  display: flex;
  padding-top: 20px;
  justify-content: flex-end;
}

.warranty-center .flex-container img {
  padding-bottom: 1em;
}

.warranty-center .flex-container {
  padding-top: 2em;
  display: flex;
  width: 85%;
  flex-wrap: wrap;
  max-width: 960px;
  margin: 0 auto;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.warranty-center .form-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.warranty-center .progress-bar {
  flex-direction: inherit;
  text-align: inherit;
  justify-content: inherit;
  white-space: inherit;
  overflow: inherit;
  display: flex;
  flex-wrap: wrap;
  background-color: inherit;
  margin-bottom: 2em;
  width: 100%;
}

.warranty-center .progress-bar .progress-icon.active {
  transition: 0.2s ease-in-out;
}

.warranty-center .progress-bar .progress-icon.active p,
.warranty-center .progress-bar .progress-icon.active i {
  opacity: 1;
}

.warranty-center .progress-bar .progress-icon {
  width: 20%;
}

.warranty-center .progress-bar .progress-icons {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.warranty-center .progress-bar .progress-icons p {
  font-size: 13px;
}

.warranty-center .progress-bar .progress-icons p,
.warranty-center .progress-bar .progress-icons i {
  transition: 1200ms cubic-bezier(0.22, 1, 0.36, 1);
  opacity: 0.2;
  color: white;
}

.warranty-center .progress-bar .progress-icons p {
  text-align: center;
}

.warranty-center .progress-bar .progress-icons i {
  font-size: 2em;
}

.warranty-center .progress-tube {
  height: 15px;
  width: 100%;
  border-radius: 25px;
  background-color: #192732;
  position: relative;
  transition: 1200ms cubic-bezier(0.22, 1, 0.36, 1);
}

.warranty-center .tube-gel {
  transition: 1200ms cubic-bezier(0.22, 1, 0.36, 1);
  width: 100%;
  height: 100%;
  background: #1bc891;
  left: 0;
  top: 0;
  position: absolute;
  overflow: hidden;
  border-radius: 25px;
}

.warranty-center .warranty-adder {
  padding: 2em;
}

.warranty-adder .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  background: white;
  margin-bottom: 1.2em;
  border-radius: 4px;
}

.warranty-center .warranty-adder button#dark-bg-button {
  color: #020203 !important;
}

.warranty-center button#dark-bg-button:hover {
  color: white !important;
}

.warranty-products .item {
  display: flex;
  background-color: #280781;
  border-radius: 8px;
  padding: 1em;
  box-sizing: border-box;
  margin-top: 10px;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.warranty-products .item background .fa-check-circle {
  padding-right: 10px;
}

.warranty-products .item .fa-check-circle {
  color: #1bc891;
  margin-right: 10px;
}

.warranty-products .item .item-meta {
  flex: 9 1;
  color: white;
}

.warranty-products .item .item-meta .item-serial {
  padding-top: 5px;
  font-size: 0.75em;
}

.warranty-products .item .fa-trash {
  color: white;
  text-align: center;
  flex: 1 1;
}

#dark-bg-button.Mui-disabled {
  background: #330aa0;
}

.warranty-center .centered-text {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  max-width: 400px;
  color: white;
}

.warranty-header {
  margin: 0 auto;
  padding: 1em;
  background: #192732;
}

.warranty-header-item {
  font-size: 1em;
  color: white;
  padding: 0.25em 1em;
  cursor: pointer;
  margin-right: 30px;
}

.warranty-header-item.active {
  background-color: #440dd7;
  border-radius: 25px;
}

.warranty-input {
  background-color: white;
}

.warranty-center .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart {
  background: white;
}

.warranty-center input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart.MuiOutlinedInput-inputAdornedStart::-webkit-input-placeholder {
  opacity: 0.75;
}

.warranty-center input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart.MuiOutlinedInput-inputAdornedStart:-ms-input-placeholder {
  opacity: 0.75;
}

.warranty-center input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart.MuiOutlinedInput-inputAdornedStart::placeholder {
  opacity: 0.75;
}

.warranty-center .MuiOutlinedInput-notchedOutline {
  border: none;
}

#warranty-text-header {
  margin-bottom: 2em;
}

#warranty-text-header p {
  color: #9d9d9d;
}

.MuiGrid-root.MuiDropzonePreviewList-root.MuiGrid-container.MuiGrid-spacing-xs-8 {
  padding-bottom: 3em;
}

img.MuiDropzonePreviewList-image {
  width: auto;
  height: auto;
  min-width: 150px;
}

.warranty-center p.MuiTypography-root.MuiDropzoneArea-text.MuiTypography-h5 {
  color: black;
  font-size: 0.75em;
  opacity: 0.8;
}

#warranty-text-header a {
  margin: 0 5px;
  color: #1bc891;
  border-bottom: 1px solid #440dd7;
}

.warranty-center svg.MuiSvgIcon-root.MuiDropzoneArea-icon {
  width: 175px;
  height: 175px;
}

p.info-tooltip {
  display: inline;
  display: initial;
  cursor: pointer;
  border-bottom: 1px solid;
  color: #b39cf1;
  font-size: 0.9em;
}

.warranty-center .button-group #dark-bg-button.Mui-disabled {
  background-color: #192732;
  color: #4c5760;
}

.warranty-center p.MuiFormHelperText-root.MuiFormHelperText-contained.MuiFormHelperText-filled {
  color: #b39cf1;
}

.success-ani svg {
  width: 50px;
  display: block;
  margin: 0 auto;
}

.success-ani .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.success-ani .path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.success-ani .path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}

.success-ani .path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

.success-ani p {
  text-align: center;
  font-size: 1.25em;
}

.success-ani p.success {
  margin: 1em 0;
  color: #b39cf1;
}

.success-ani p.error {
  color: #d06079;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

.registration-item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.registration-item-image {
  width: 80px;
  position: relative;
  margin-right: 20px;
}

.registration-item-image img {
  width: 40px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
}

.MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  background: #192732;
}

.registration-item-container h2 {
  color: white;
  font-size: 1em;
}

.registration-bubble {
  width: 75px;
  height: 75px;
  background: #440dd7;
  border-radius: 50%;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.MuiAccordionDetails-root {
  background: #131c24;
}

.registration-meta {
  color: white;
}

.registration-serial {
  font-weight: bold;
}

.registration-date {
  color: #626a70;
}

.registration-product {
  width: 100%;
}

.registration-product i.fas.fa-receipt {
  text-align: center;
  margin: 0 auto;
  color: #cedde9;
  font-size: 1;
}

.registration-flex {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #05080a;
  padding: 1em 0;
}

.registration-receipt {
  display: flex;
  align-items: center;
  width: 200px;
  border: 1px solid #505d67;
  padding: 0.75em 1.2em;
  border-radius: 8px;
  cursor: pointer;
}

.registration-receipt span {
  color: #a6adb2;
}

.registration-receipt:hover {
  background: #192732;
  transition: 0.2s ease-in-out;
}

.registration-flex:last-child {
  border-bottom: none;
}

.registration-meta {
  width: 95%;
}

.warranty-library {
  margin: 0 auto;
  margin-top: 1em;
  max-width: 1200px;
}

.receipt-container {
  height: 100%;
  max-width: 1140px;
}

.close {
  background: #22066a;
  color: white;
  border-radius: 8px;
  padding: 3px 5px;
}

.full-height-container {
  display: flex;
  justify-content: center;
  height: 70vh;
  min-height: 768px;
  align-items: center;
}

.warranty-receipts {
  display: flex;
}

.warranty-receipts img {
  width: 100%;
  max-width: 250px;
  padding: 1em;
}

.receipt-container img {
  max-width: 100%;
}

.remove-can {
  position: absolute;
  right: 0;
  width: 40px;
  height: 40px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 0.75em;
  cursor: pointer;
}

.remove-can i {
  color: black;
}

.receipt-item {
  margin-top: 1em;
  position: relative;
}

.warranty-center button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.btn.MuiButton-containedSecondary {
  background: transparent;
  border: 2px solid #081016;
  background: #081016;
}

span.warranty-expired {
  background: #1bc891;
  padding: 4px;
  border-radius: 4px;
  margin-left: 0.5em;
  color: black;
  font-weight: bold;
}

.warranty-purchase-flex {
  display: flex;
  flex-wrap: wrap;
}

.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth {
  background: white;
  border-radius: 5px;
}

.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth input::-webkit-input-placeholder {
  color: black !important;
  opacity: 1;
}

.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth input:-ms-input-placeholder {
  color: black !important;
  opacity: 1;
}

.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal.MuiFormControl-fullWidth input::placeholder {
  color: black !important;
  opacity: 1;
}

.warranty-center .MuiRadio-colorSecondary.Mui-checked {
  color: orangered;
}

.warranty-center span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-size: 1em;
}

.warranty-center .MuiFormHelperText-root.Mui-error {
  background: #f44336;
  padding: 7px;
  border-radius: 5px;
  margin-bottom: 1em;
  color: #120201 !important;
}

.register-header-right p {
  font-size: .75em;
}

/* END BASE */

@media screen and (min-width: 768px) {
  .warranty-center .flex-container {
    padding-top: 3em;
  }

  .warranty-center p.MuiTypography-root.MuiDropzoneArea-text.MuiTypography-h5 {
    font-size: 1.2em;
  }
}

.create-account {
  text-transform: uppercase;
  font-size: .75em !important;
  font-weight: bold;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-top: 1em;
  color: rgb(79, 79, 79);
}
